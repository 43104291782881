import GroupType from './GroupType';
//temp
export type ClientGroupTypeConfig = {
  projectedDischarge: {
    enabled: boolean;
  };
  utilizationManagement: {
    enabled: boolean;
    planTypeClassification: string[];
  };
};
interface IClientGroupTypeOptions {
  id: string;
  config: ClientGroupTypeConfig;
  groupType: GroupType;
}

export const defaults: IClientGroupTypeOptions = {
  id: '',
  config: {
    projectedDischarge: {
      enabled: false,
    },
    utilizationManagement: {
      enabled: false,
      planTypeClassification: [],
    },
  },
  groupType: new GroupType(),
};

export default class ClientGroupType implements IClientGroupTypeOptions {
  id: string;
  groupType: GroupType;
  config: ClientGroupTypeConfig;

  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.config = opts.config;
    this.groupType = new GroupType(opts.groupType);
  }
}
